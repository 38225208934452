.pdf_viewer__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px 0;
    padding: 10px;
}

.pdf_viewer__container__load {
    margin-top: 1em;
    color: white;
}

.pdf_viewer__container__document {
    margin: 1em 0;
}

.pdf_viewer__container__document .react-pdf__Document {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pdf_viewer__container__document .react-pdf__Page {
    max-width: calc(100% - 2em);
    border: 1px solid #d9dee3;
    margin: 1em;
}

.pdf_viewer__container__document .react-pdf__Page canvas {
    max-width: 100%;
    height: auto !important;
}

.pdf_viewer__container__document .react-pdf__message {
    padding: 20px;
    color: white;
}